<template>
  <PageDynamicContent
    contentEndpoint="cookies"
    pageTitle="Cookies"
  ></PageDynamicContent>
</template>

<script>
import PageDynamicContent from "@/components/PageDynamicContent.vue";
import { incrementComponentsLoading } from "@/events";

export default {
  components: {
    PageDynamicContent
  },

  beforeRouteLeave(to, from, leave) {
    this.$emit(incrementComponentsLoading);
    leave();
  }
};
</script>
